import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image";
import BaseContainer from "../components/common/BaseContainer";
import {Box, Grid, styled} from '@mui/material';
import Typography from "@mui/material/Typography";
import CallToAction from "../components/common/callToAction";
import {Link} from "gatsby";

const Wrapper = styled('div')(({theme}) => ({
  position: 'relative',
  '&::before': {
    content: "''",
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: theme.palette.primary.light,
  },
  [theme.breakpoints.up('md')]: {
    '&::before': {
      clipPath: 'polygon(0 0, 51% 0, 61% 100%, 0% 100%)',
    }

  }
}))

const Hero = () => (
  <Wrapper>
    <Box sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      width: '50%',
      height: '100%',
      zIndex: '-10',
    }}
    >
      <StaticImage src='../images/city.png' alt='hero city'
                   imgStyle={{objectPosition: '0 30%'}}
                   style={{height: '100%'}}/>
    </Box>
    <BaseContainer sx={{py: 8, position: 'relative'}} component='section'>
      <Typography variant='h1' color='secondary.main'
                  sx={{
                    fontSize: {xs: '66px', md: '96px', lg: '130px'},
                    lineHeight: {xs: '62px', md: '90px', lg: '112px'},
                  }}
      >
        KOMPLEKSOWA<br/>
        OBSŁUGA<br/>
        W ZAKRESIE<br/>
        AUDIOMARKETINGU
      </Typography>
      <Typography variant='h3' color='secondary.main'
                  sx={{
                    py: 3,
                    fontSize: {xs: '33px', md: '48px', lg: '66px'},
                    lineHeight: {xs: '30px', md: '44px', lg: '60px'},
                  }}
      >
        Tworzenie tożsamości marki<br/>
        – Audio branding<br/>
        piosenki identyfikujące markę, jingle
      </Typography>
    </BaseContainer>
  </Wrapper>
)

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero />
  </Layout>
)

export default IndexPage
